import React, {useState, useLayoutEffect} from "react";
import Layout from "../layout";
import SetLang from "../components/setLang";
import Boxes from "../components/boxes";
import Hero from "../components/hero";
import Heading from "../components/heading";
import Img from "gatsby-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { graphql, Link } from "gatsby";
import Parallax from "../components/parallax";
import Paragraph from "../components/asideParagrah";
import styled from "styled-components";
import { scale, rhythm } from "../utils/typography";
import { SpecialtiesForm } from "../components/specialtiesForm";
import ReactHtmlParser from "react-html-parser";
import Content, { HTMLContent } from "../components/content";
import { ContactWidget } from "../components/ContactWidget";
import { ContactPopUp } from "../components/contactPopUp";
import { AnnexsRow } from "../components/annexsRow";
import { AnnexsRowMultiple } from "../components/annexsRowMultiple";
import ListGalleryChecks from "../components/ListGalleryChecks";
import ListGalleryColumn from "../components/ListGalleryColumn";
import ListGalleryBlocks from "../components/ListGalleryBlocks";
import ListGalleryList from "../components/ListGalleryList";
import { customBlocksColumn } from "../components/customBlocksColumn";
import { customBlocksRow} from "../components/customBlocksRow"
import { TakeCareYourBrackets, takeCareYourBrackets } from "../components/takeCareYourBrackets";

const AnnexPageContainer = styled.div`
  
  .paragraph {
    margin-top: 3rem;

    @media(min-width: 1024px) {
      margin-top: 0;
    }
  }
`;

const Article = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction === "reverse" ? "row-reverse" : "row"};
  padding: 4rem 5vw 1.5rem;
  justify-content: space-evenly;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  a {
    margin-top: -1rem;
    margin-bottom: 3rem;
    width: 177px;
    height: 40px;
    border: 1px solid rgb(34, 34, 34);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    text-decoration: none;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: #333;
  
    &:hover {
      background-color: #ededed;
      border: none;
    }
  }
  article {
    width: fit-content;
    display: flex;
    max-width: 800px;
    flex-direction: column;

    height: fit-content;
    .left {
      text-align: left;
    }
    .section-title {
      margin-bottom: ${rhythm(1)} !important;
      margin-top: 0.5rem;
    }
    ul {
      list-style: none;
      margin-bottom: 0 !important;
      li {
        padding-left: 0.5rem;
      }
      .icon-icono-check {
        border-radius: 50%;
        padding: 2px;
        margin-right: 0.8rem;
    }
    }
    ul.check-list {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      list-style: none;
      li {
        position: relative;
        padding-left: 40px;
  
        .icon-icono-check {
          position: absolute;
          left: 0px;
          top: 3px;
          padding: 3px;
          border-radius: 100%;
        }
      }
    }
    ul.checklist {
      margin-left: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      list-style: none;
      li {
        position: relative;
        padding-left: 40px;
  
        .icon-icono-check {
          position: absolute;
          left: 0px;
          top: 3px;
          padding: 3px;
          color: white;
          border-radius: 50%;
        }
      }
    }
    ul.options {
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 3rem;
      @media screen and (max-width: 480px) {
        margin-bottom: 1rem;
        flex-direction: column;
        li {
          margin-bottom: ${rhythm(2)};
        }
      }
      li {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        span {
          font-size: 85px;
        }
        h2 {
          color: #91c508;
        }
        h3 {
          font-weight: 400;
        }
      }
    }
    .icon {
      width: 100%;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      &:before,
      :after {
        background-size: contain !important;
        background-position: left !important;
      }
    }
  }
  p {
    @media screen and (min-width: 768px) {
      width: 80%;
    }
  }
  .image {
    width: 100%;
    max-width: 420px;
    max-height: 420px;
    border-radius: 50%;
    display: flex;
    border: 1px solid #4c4c4c !important;
    margin-bottom: ${rhythm(2)};
    div {
      padding-bottom: 100% !important;
    }
  }
`;

const Slogan = styled(BackgroundImage)`
  padding: 4rem calc(5vw) 3rem;
  color: white;
  text-align: center;
  a {
    background: #222;
    padding: 14px 25px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-text-decoration: none;
    text-decoration: none;
    border: solid 1px white;
    margin-top: ${rhythm(2)} !important;
    margin-bottom: ${rhythm(1)} !important;
    display: block !important;
    width: fit-content;
    min-width: 170px;
    margin: auto;
    transition: all 0.25s linear;
    &:hover {
      background: white;
      color: #333;
    }
  }
  h2 {
    font-weight: 400;
  }
`;

const ListGallery = styled.section`
  /* padding: ${rhythm(4)} 5vw ${rhythm(3)}; */
  padding: ${rhythm(4)};
  padding-bottom: 2rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-icon {
    display:flex;
    flex-direction:row;
  }

  .icon-title {
    width:10%;
    margin: 0 auto;
    font-size:48px;
  }
  solid 1px #222

  }

  @media (min-width: 768px){
    .icon-title {
      margin: 0;
    }
  }

  @media (max-width: 768px){
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem !important;
  }

  @media (min-width: 768px){
    h2 {
      margin-top: 0.2rem;
    }
  }
  .subtitle {
    text-align: center;
    margin-bottom: 0.6rem;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    .item {
      .dv-div-title {
        display: flex;
        align-items: center;
        span {
          color: #91c508;
          margin-right: 1rem;
          float: left;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }
      }
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-basis: 50%;
      padding-top: 1.6rem;
      padding-bottom: 1rem;
      &:nth-of-type(even) {
        &.List {
          flex-direction: row !important;
        }
        flex-direction: row-reverse;
      }
      &.List {
        flex-basis: 100% !important;
        align-items: center;
        justify-content: space-evenly;
        /* border-bottom: solid 35px #ededed; */
        margin-bottom: ${rhythm(1)};
        @media screen and (max-width: 1024px) {
          flex-direction: column !important;
        }
        .image {
          margin: 0;
        }
        .content {
         
          display: flex;
          flex-direction: row;
          text-align: left;
          padding-left: 5vw;
          justify-content: center;
          align-items: center;
          height: fit-content;
          max-width: 800px;
          @media screen and (max-width: 1024px) {
            flex-direction: column;
            max-width: 500px;
            span {
              align-self: flex-start;
            }
          }
        }

        @media (max-width: 768px){
          .content {
            margin-bottom: -60px !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
          }
        }
      }
      &.columnb {
        flex-direction: column !important;
        p {
          color: #555 !important;
          text-align: left !important;
          width: 100% !important;
          max-width: 500px !important;
          margin-left: 5vw;
          @media screen and (max-width: 1024px) {
            margin-left: 10vw;
          }
        }
      }
      .image.List {
        border: solid #999;
        max-width: 500px !important;
        width: 100% !important;
      }
      .image.columnb {
        width: 90% !important;
        max-width: 500px !important;
        margin: 0 auto !important;
        padding: 0 !important;
        @media screen and (max-width: 1024px) {
          width: 100% !important;
        }
      }

      @media screen and (max-width: 1250px) {
        flex-direction: column-reverse !important;
        &.List {
          flex-direction: row !important;
        }
        .image {
          padding-right: 50px;
          &:nth-of-type(even) {
            padding-left: 50px;
          }
        }
      }
      @media screen and (max-width: 1024px) {
        &.List {
          flex-direction: column !important;
        }
        &.columnb {
          flex-direction: column !important;
          .content {
            height: fit-content;
            padding-bottom: 0;
            p {
              margin-bottom: 16px !important;
              line-height: normal;
            }
          }
        }
        flex-direction: row !important;
        flex-basis: 100% !important;
        &:nth-of-type(even) {
          flex-direction: row-reverse !important;
          &.List {
            flex-direction: column !important;
          }
          &.columnb {
            flex-direction: column !important;
          }
        }
        .image {
          padding-right: 0;
          &:nth-of-type(even) {
            padding-left: 0;
          }
        }
      }
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse !important;
        flex-basis: 100% !important;

        &:nth-of-type(even) {
          flex-direction: column-reverse !important;
        }
        .image {
          padding-right: 0;
          &:nth-of-type(even) {
            padding-left: 0;
          }
        }
      }
      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }
      .step-icon {
        ${scale(1)};
        font-family: Bebas Neue Bold;
        color: #91c508;
        border: solid #91c508;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 0;
        padding-top: 4px;
        margin-bottom: 1.2rem;
      }
      .image {
        display: flex;
        width: 100%;
        max-width: 450px;
        margin: auto;
        img {
          object-fit: contain !important;
        }
      }
      .icon-icono-check {
        border-radius: 50%;
        padding: 2px;
        margin-left: -40px;
        margin-right: 7px;
      }
      .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        padding: 25px;
        align-items: center;
        text-align: center;
      }
    }

  }
`;

const CustomBlock = styled.section`
  .column {
    padding: 3rem calc(5vw - 15px) 1rem;
    display: flex;
    flex-direction: column;
    .icon {
      font-size: 45px;
      color: #91c508;
      font-family: Bebas Neue Bold;
      height: 50px;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      justify-content: center;
      display: flex;
      align-items: center;
      line-height: 0;
      border: solid;
      border-radius: 50%;
      padding: 8px 0 0 2px;
      margin-right: 5vw;
      margin-bottom: 1rem;
    }
    p {
      text-align: left;
    }
    h2 {
      text-align: center;
    }
    .block {
      flex-basis: 50%;
      padding: 15px !important;
      margin: auto !important;
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-flow: wrap;

      .block-header,
      .block-body,
      .block-footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        p {4rem
          margin-top: ${rhythm(1)};
        }
      }

      .block-footer {
        @media(max-width: 1024px) {
          margin-top: 4rem;
        }
      }
      .block-body {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        width: 100%;
        .gatsby-image-wrapper {
          display: flex;
          width: 100%;
          flex-basis: 100%;
          @media screen and (min-width: 768px) {
            flex-basis: 50% !important;
            img {
              object-fit: contain !important;
            }
            &:nth-of-type(odd) {
              padding-right: 2.5vw !important;
              img {
                object-position: center left !important;
              }
            }
            &:nth-of-type(even) {
              padding-left: 2.5vw !important;
              img {
                object-position: center right !important;
              }
            }
          }
        }
      }
      .image {
        margin-top: 0.5rem;
        margin-bottom: 0rem;

        @media(min-width: 1024px) {
          margin-top: ${rhythm(1)};
          margin-bottom: ${rhythm(2)};
        }
      }
    }
  }
  .row {
    padding: 4rem calc(5vw - 15px) 3rem;
    display: flex;
    flex-direction: row;

    flex-flow: wrap;
    justify-content: center;
    p {
      text-align: center;
    }
    h2 {
      text-align: center;
    }
    .block {
      flex-basis: 50%;
      margin: auto !important;
      display: flex;
      width: 100%;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        &:nth-of-type(odd) {
          padding-right: 2.5vw !important;
        }
        &:nth-of-type(even) {
          padding-left: 2.5vw !important;
        }
      }
      .image {
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column !important;
    .block {
      padding: 0 5vw!important;
      flex-basis: 100% !important;
      &:not(:last-child) {
        margin-bottom: 2.5rem !important;
      }
    }
  }
`; 
const ListContainer = (props) => {
  const { content, blocks, type, position } = props;
  
  return (
      <>
        <ListGallery>
      {/*type === "Column" || (type === "columnb" && ReactHtmlParser(content))*/}
      {ReactHtmlParser(content)}
      <div className="wrapper">
        {blocks.map((i, k) => {
          return (
            <div key={k} className={`item ${type}`}>
              {i.img && (
                <>
                  <Img critical={true}
                      className={`image ${type}`}
                      fluid={i.img.childImageSharp.fluid}
                      style={{border: "2px solid #a0a0a0"}}
                  ></Img>
                </>
              )}
              <div className={`content ${type}`}>
                {type === "Column" && (
                  <span className="step-icon">{i.number}</span>
                )}
                {ReactHtmlParser(i.title)}
              </div>
            </div>
          );
        })}
      </div>
    </ListGallery>
      </>
  );
};

const getListGallery = (typeGallery, data) => {
  
  switch(typeGallery) {
    case "checks":
      return <ListGalleryChecks {...data} />
    case "column":
      return <ListGalleryColumn {...data} />
    case "block":
      return <ListGalleryBlocks {...data} />
    case "list":
      return <ListGalleryList {...data} />  
  }
}

const getCustomBlocks = (customBlock) => {
  switch(customBlock.type) {
    case "column":
      return <customBlocksColumn {...customBlock} />
    case "row":
      return <customBlocksRow {...customBlock} />
  }
}

export const AnnexPageTemplate = ({
  language,
  content,
  contentComponent,
  articleBlock,
  hero,
  heading,
  parallax,
  procedures,
  slogan,
  customBlocks,
  listGallery,
  anexes,
  form,
  forms,
  annexes_row,
  takeYourCare,
  final_content
}) => {
  const PostContent = contentComponent || Content;
  console.log("final content ",final_content);
  return (
    <AnnexPageContainer>
      <Hero className="center single half" {...hero}></Hero>
      <Heading color="#ededed" {...heading} />
      {anexes.display && (
        <div className="paragraph"><Paragraph  enforce={anexes.enforce} contained={false} {...anexes} /></div>
      )}
      {/*listGallery.display && listGallery.position === "top" && (
        <ListContainer {...listGallery}></ListContainer>
      )*/}
      {listGallery.display && listGallery.position === "top" && (
        getListGallery(listGallery.type_gallery,listGallery)
      )}
      {customBlocks.display && (
        <CustomBlock>
          <div className={customBlocks.type}>
            {customBlocks.blocks.map((i, k) => {
              return (
                <div key={k} className="block">
                  {i.header.display && (
                    <div className="block-header">
                      {ReactHtmlParser(i.header.content)}
                    </div>
                  )}

                  {i.body.display && (
                    <div className="block-body">
                      {i.body.images.map((item, key) => {
                        return (
                          <Img critical={true} key={key}
                            className="image"
                            fluid={item.src.childImageSharp.fluid}
                          ></Img>
                        );
                      })}
                    </div>
                  )}
                  {i.footer.display && (
                    <div className="block-footer">
                      {ReactHtmlParser(i.footer.content)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </CustomBlock>
          )}
  
      {slogan.img !== null ? (
        <>
          <Slogan fluid={slogan.img.childImageSharp.fluid}>
                {ReactHtmlParser(slogan.content)}
          </Slogan>
        </>
      ) : null}
      {/* <StyledContent>
        <PostContent content={content} />
      </StyledContent> */}
      {(!takeYourCare && !final_content) && <AnnexsRow {...annexes_row} />}
      {(!takeYourCare && final_content) && <AnnexsRowMultiple {...final_content} />}
      {takeYourCare && <TakeCareYourBrackets {...takeYourCare} />}

      {/*listGallery.display && listGallery.position === "bottom" && (
        <>
          <ListContainer {...listGallery}></ListContainer>
        </>
      )*/}
      {listGallery.display && listGallery.position === "bottom" && (
        getListGallery(listGallery.type_gallery,listGallery)
      )}
      <Parallax nocontent={true} img={parallax.img} ></Parallax>
      <Article direction={articleBlock.direction}>
        <article>
          {ReactHtmlParser(articleBlock.content.body)}
          {articleBlock.content.image.src !== null && articleBlock.content.image.display && (
            <BackgroundImage critical={true}
              style={{
                paddingBottom: articleBlock.content.image.size,
              }}
              fluid={articleBlock.content.image.src.childImageSharp.fluid}
              className="icon"
            />
          )}
          <br></br>
          {articleBlock.content.link.display && (
            <Link to={articleBlock.content.link.to}>
              {articleBlock.content.link.placeholder}
            </Link>
          )}
        </article>
        {articleBlock.img !== null && (
          <>
            <Img critical={true}
                className="image"
                fluid={articleBlock.img.childImageSharp.fluid}
            ></Img>
          </>
        ) }
      </Article>
      <SpecialtiesForm
        type="extended"
        data={forms.specialties}
        title={form.title}
        language={language}
        img={form.background}
      ></SpecialtiesForm>
      <Boxes {...procedures}></Boxes>
    </AnnexPageContainer>
  );
};

const AnnexPage = ({ data }) => {
  const {
    templateKey,
    customBlocks,
    language,
    title,
    redirects,
    listGallery,
    hero,
    heading,
    parallax,
    procedures,
    anexes,
    slogan,
    articleBlock,
    description,
    keywords,
    form,
    annexes_row,
    final_content,
    takeYourCare
  } = data.markdownRemark.frontmatter;

  const [isContactWidgetOpen, setIsContactWidgetOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isPortrait,setIsPortrait] = useState(false);

  const changeOpenWidget = () => {
    setIsContactWidgetOpen(!isContactWidgetOpen);
  };

  useLayoutEffect(() => {

    if(typeof window !== "undefined"){
      
      const updateSize = () => {

          let width = window.screen.width;
          let height = window.screen.height;

          if(width < 1026){
      
            setIsMobile(true);
          }else{
        
            setIsMobile(false);
          }

          if(height > width){
            
            setIsPortrait(true);
          }else{
       
            setIsPortrait(false);
          }
      };

      window.addEventListener("resize",updateSize);
    }
  });

  return (
    <Layout>
      <SetLang language={language} link={redirects} />
      <SEO
        title={title}
        lang={language}
        description={description}
        keywords={keywords}
      />
      <AnnexPageTemplate
        content={data.markdownRemark.html}
        contentComponent={HTMLContent}
        {...{
          templateKey,
          listGallery,
          language,
          articleBlock,
          title,
          customBlocks,
          redirects,
          hero,
          heading,
          parallax,
          anexes,
          slogan,
          procedures,
          form,
          annexes_row,
          takeYourCare,
          final_content
        }}
      />


      <div>
        <ContactWidget changeOpenWidget={changeOpenWidget}/>

        {isContactWidgetOpen == true ? (
          <>
           <ContactPopUp changeOpenWidget={changeOpenWidget} />
          </>
        ) : <></>}

      </div>
    </Layout>
  );
};

export default AnnexPage;

export const pageQuery = graphql`
  query AnnexPage($id: String!) {
    markdownRemark(
      id: { eq: $id }

      frontmatter: { templateKey: { eq: "annex-page" } }
    ) {
      html
      frontmatter {
        language
        title
        description
        keywords
        redirects
        heading {
          display
          content
        }
        hero {
          background {
            scaleOnReveal
            img {
              childImageSharp {
                fluid(quality: 100, srcSetBreakpoints: [1500]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            isParallax
          }
          anim {
            display
            type
          }
          height
          indicator
          portraitPosition
          content {
            position
            body
          }
        }
        customBlocks {
          display
          type
          blocks {
            header {
              display
              content
            }
            body {
              display
              images {
                src {
                  childImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [1500]) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            footer {
              display
              content
            }
          }
        }
        articleBlock {
          direction
          img {
            childImageSharp {
              fluid(srcSetBreakpoints: [800], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content {
            body
            image {
              display
              size
              src {
                childImageSharp {
                  fluid(srcSetBreakpoints: [800], quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            link {
              display
              to
              placeholder
            }
          }
        }
        listGallery {
          display
          type_gallery
          position
          type
          content
          blocks {
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [800], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            number
            title
          }
        }
        anexes {
          enforce
          display
          items {
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [800], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            content
            footer {
              icon {
                display
                size
                img {
                  childImageSharp {
                    fluid(srcSetBreakpoints: [400], quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              link {
                display
                to
                placeholder
              }
            }
          }
        }

        form {
          title
          background {
            childImageSharp {
              fluid(srcSetBreakpoints: [1500], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        slogan {
          content
          img {
            childImageSharp {
              fluid(srcSetBreakpoints: [1500], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        parallax {
          portraitPosition
          img {
            childImageSharp {
              fluid(srcSetBreakpoints: [1500], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        procedures {
          title
          procedures {
            title
            to
            img {
              childImageSharp {
                fluid(srcSetBreakpoints: [550], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        annexes_row {
          row {
            thereImageContent
            title
            image {
              publicURL
            }
            text
            description
          }
        }
        final_content {
          multiple
          data {
            row {
              thereImageContent
              title
              image
              text
            }
          }
        }
        takeYourCare {
          introduction
          row {
            image {
              publicURL
            }
            icon {
              publicURL
            }
            text
          }
        }
      }
    }
  }
`;
