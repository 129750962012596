import React from 'react'
import styled from 'styled-components';
import { rhythm, scale } from '../utils/typography';
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";

const ListGallery = styled.section`
    padding: ${rhythm(4)};
    @media(min-width: 1024px) {
      padding-top: 4rem;
    }
    padding-bottom: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dv-div-text {
      margin-top: -10px;
      margin-bottom: -4px;
    }

    h2 {
      text-align: center;
    }
  
    .title-icon {
      display:flex;
      flex-direction:row;
    }
  
    .icon-title {
      width:10%;
      margin: 0 auto;
      font-size:48px;
    }
    solid 1px #222

    }
  
    @media (min-width: 768px){
      .icon-title {
        margin: 0;
      }
    }
  
    @media (max-width: 768px){
      padding-right: 1rem;
      padding-left: 1rem;
      padding-top: 4rem;
      padding-bottom: 3.5rem !important;
    }
  
    @media (min-width: 768px){
      h2 {
        margin-top: 0.2rem;
      }
    }
    .subtitle {
      text-align: center;
      margin-bottom: 0.6rem;
    }
    .wrapper {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      .item {
        .dv-div-title {
          display: flex;
          align-items: center;
          span {
            color: #91c508;
            margin-right: 1rem;
            float: left;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
        }
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-basis: 50%;
        padding-top: 1.6rem;
        padding-bottom: 1rem;
        &:nth-of-type(even) {
          &.List {
            flex-direction: row !important;
          }
          flex-direction: row-reverse;
        }
        &.List {
          flex-basis: 100% !important;
          align-items: center;
          justify-content: space-evenly;
          /* border-bottom: solid 35px #ededed; */
          margin-bottom: ${rhythm(1)};
          @media screen and (max-width: 1024px) {
            flex-direction: column !important;
          }
          .image {
            margin: 0;
          }
          .content {
           
            display: flex;
            flex-direction: row;
            text-align: left;
            padding-left: 5vw;
            justify-content: center;
            align-items: center;
            height: fit-content;
            max-width: 800px;
            @media screen and (max-width: 1024px) {
              flex-direction: column;
              max-width: 500px;
              span {
                align-self: flex-start;
              }
            }
          }
  
          @media (max-width: 768px){
            .content {
              margin-bottom: -60px !important;
              padding-right: 0 !important;
              padding-left: 0 !important;
            }
          }
        }
        &.columnb {
          flex-direction: column !important;
          p {
            color: #555 !important;
            text-align: left !important;
            width: 100% !important;
            max-width: 500px !important;
            margin-left: 5vw;
            @media screen and (max-width: 1024px) {
              margin-left: 10vw;
            }
          }
        }
        .image.List {
          border: solid #999;
          max-width: 500px !important;
          width: 100% !important;
        }
        .image.columnb {
          width: 90% !important;
          max-width: 500px !important;
          margin: 0 auto !important;
          padding: 0 !important;
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }
        }
  
        @media screen and (max-width: 1250px) {
          flex-direction: column-reverse !important;
          &.List {
            flex-direction: row !important;
          }
          .image {
            padding-right: 50px;
            &:nth-of-type(even) {
              padding-left: 50px;
            }
          }
        }
        @media screen and (max-width: 1024px) {
          &.List {
            flex-direction: column !important;
          }
          &.columnb {
            flex-direction: column !important;
            .content {
              height: fit-content;
              padding-bottom: 0;
              p {
                margin-top: -10px;
                margin-bottom: 16px !important;
                line-height: normal;
              }
            }
          }
          flex-direction: row !important;
          flex-basis: 100% !important;
          &:nth-of-type(even) {
            flex-direction: row-reverse !important;
            &.List {
              flex-direction: column !important;
            }
            &.columnb {
              flex-direction: column !important;
            }
          }
          .image {
            padding-right: 0;
            &:nth-of-type(even) {
              padding-left: 0;
            }
          }
        }
        @media screen and (max-width: 768px) {
          flex-direction: column-reverse !important;
          flex-basis: 100% !important;
  
          &:nth-of-type(even) {
            flex-direction: column-reverse !important;
          }
          .image {
            padding-right: 0;
            &:nth-of-type(even) {
              padding-left: 0;
            }
          }
        }
        &:nth-of-type(even) {
          flex-direction: row-reverse;
        }
        .step-icon {
          ${scale(1)};
          font-family: Bebas Neue Bold;
          color: #91c508;
          border: solid #91c508;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          width: 50px;
          text-align: center;
          line-height: 0;
          padding-top: 4px;
          margin-bottom: 1.2rem;
        }
        .image {
          display: flex;
          width: 100%;
          max-width: 450px;
          margin: auto;
          img {
            object-fit: contain !important;
          }
        }
        .icon-icono-check {
          border-radius: 50%;
          padding: 2px;
          margin-left: -40px;
          margin-right: 7px;
        }
        .content {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          padding: 25px;
          align-items: center;
          text-align: center;
        }
      }
  
    }
  `;

export default function ListGalleryColumn(props) {
  
  const { content, blocks, type, position } = props;
  
  return (
      <>
        <ListGallery>
        
            {ReactHtmlParser(content)}
                <div className="wrapper">
                    {blocks.map((i, k) => {
                        return (
                            <div key={k} className={`item ${type}`}>
                                {i.img && (
                                    <>
                                        <Img critical={true}
                                            className={`image ${type}`}
                                            fluid={i.img.childImageSharp.fluid}
                                            style={{border: "2px solid #a0a0a0"}}
                                        ></Img>
                                    </>
                                )}
                                <div className={`content ${type}`}>
                                    {type === "Column" && (
                                        <span className="step-icon">{i.number}</span>
                                    )}
                                    {ReactHtmlParser(i.title)}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </ListGallery>
      </>
    )
}
