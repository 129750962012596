import React from 'react'
import styled from 'styled-components'
import { rhythm } from '../utils/typography';

const CustomBlockContainer = styled.section`

.column {
    padding: 3rem calc(5vw - 15px) 1rem;
    display: flex;
    flex-direction: column;
    .icon {
      font-size: 45px;
      color: #91c508;
      font-family: Bebas Neue Bold;
      height: 50px;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      justify-content: center;
      display: flex;
      align-items: center;
      line-height: 0;
      border: solid;
      border-radius: 50%;
      padding: 8px 0 0 2px;
      margin-right: 5vw;
    }
    p {
      text-align: left;
    }
    h2 {
      text-align: center;
    }
    .block {
      flex-basis: 50%;
      padding: 15px !important;
      margin: auto !important;
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-flow: wrap;

      .block-header,
      .block-body,
      .block-footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        p {
          margin-top: ${rhythm(1)};
        }
      }

      .block-footer {
        @media(max-width: 1024px) {
          margin-top: 4rem;
        }
      }
      .block-body {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        width: 100%;
        .gatsby-image-wrapper {
          display: flex;
          width: 100%;
          flex-basis: 100%;
          @media screen and (min-width: 768px) {
            flex-basis: 50% !important;
            img {
              object-fit: contain !important;
            }
            &:nth-of-type(odd) {
              padding-right: 2.5vw !important;
              img {
                object-position: center left !important;
              }
            }
            &:nth-of-type(even) {
              padding-left: 2.5vw !important;
              img {
                object-position: center right !important;
              }
            }
          }
        }
      }
      .image {
        margin-top: 0.5rem;
        margin-bottom: 0rem;

        @media(min-width: 1024px) {
          margin-top: ${rhythm(1)};
          margin-bottom: ${rhythm(2)};
        }
      }
    }
  }

@media screen and (max-width: 768px) {
  flex-direction: column !important;
  .block {
    padding: 0 !important;
    flex-basis: 100% !important;
  }
}
`;
export const customBlocksColumn = (props) => {
  
  const { customBlocks } = props;

  return (
    <CustomBlockContainer>
      <div className={customBlocks.type}>
            {customBlocks.blocks.map((i, k) => {
              return (
                <div key={k} className="block">
                  {i.header.display && (
                    <div className="block-header">
                      {ReactHtmlParser(i.header.content)}
                    </div>
                  )}

                  {i.body.display && (
                    <div className="block-body">
                      {i.body.images.map((item, key) => {
                        return (
                          <Img critical={true} key={key}
                            className="image"
                            fluid={item.src.childImageSharp.fluid}
                          ></Img>
                        );
                      })}
                    </div>
                  )}
                  {i.footer.display && (
                    <div className="block-footer">
                      {ReactHtmlParser(i.footer.content)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
    </CustomBlockContainer>
  )
}
