import React, {useState, useEffect} from 'react'
import styled from "styled-components";
import { scale, rhythm } from "../utils/typography";

const StyledContainer = styled.div`
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;

    @media(min-width: 1024px) {
        padding: 4rem;
    }

    .row {
        margin-top: 3rem;

        @media(min-width: 1024px) {
            margin: 0;
        }
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media(min-width: 1024px) {
        flex-direction: row;
    }

    .icon {
        font-size: 45px;
        color: #91c508;
        font-family: Bebas Neue Bold;
        height: 50px;
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        justify-content: center;
        display: flex;
        align-items: center;
        line-height: 0;
        border: solid;
        border-radius: 50%;
        padding: 8px 0 0 2px;
        margin: auto;
        margin-bottom: 1rem;

        @media(min-width: 1024px) {
            margin: 0;
            margin-right: 5vw;
        }
      }

    .icon-image {
        margin-top: -1rem;
    }  

    p {
        @media(min-width: 1024px) {
            margin-bottom: 3rem;
        }
    }
`;


const Paragraph = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-center: center;
    text-align: center;
    

    p {
        text-align: left;
        margin-top: -1rem;

        @media(min-width: 1024px) {
            margin: 0;
            padding: 0 2rem;
        }
    }
`;


export const TakeCareYourBrackets = (props) => {

  console.log("take your brackets ",props);
  return (
    <StyledContainer>
        <Row>
            <span class="icon">3</span>
            <p>
                {props.introduction}
            </p>
        </Row>
        
        {props.row.map((item,index) => {
            
            return (
                <>
                    <Row key={index} className='row'>
                        <img src={item.image} />
                        <Paragraph>
                            {/* <h1><i className='icon icon-icon-brush'></i></h1> */}
                            <img className='icon-image' src={item.icon} />
                            <p>
                                {item.text}
                            </p>
                        </Paragraph>
                    </Row>
                </>
            )
        })}
    </StyledContainer>
  )
}
