import React from 'react'
import styled from 'styled-components'
import { rhythm } from '../utils/typography';

const CustomBlockContainer = styled.section`

.row {
  padding: ${rhythm(4)} calc(5vw - 15px) ${rhythm(3)};
  display: flex;
  flex-direction: row;

  flex-flow: wrap;
  justify-content: center;
  p {
    text-align: center;
  }
  h2 {
    text-align: center;
  }
  .block {
    flex-basis: 50%;
    margin: auto !important;
    display: flex;
    width: 100%;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      &:nth-of-type(odd) {
        padding-right: 2.5vw !important;
      }
      &:nth-of-type(even) {
        padding-left: 2.5vw !important;
      }
    }
    .image {
      margin-top: ${rhythm(1)};
      margin-bottom: ${rhythm(2)};
    }
  }
}

@media screen and (max-width: 768px) {
  flex-direction: column !important;
  .block {
    padding: 0 !important;
    flex-basis: 100% !important;
  }
}
`;
export const customBlocksRow = (props) => {

  const { customBlocks } = props;
  return (
    <CustomBlockContainer>
      <div className={customBlocks.type}>
            {customBlocks.blocks.map((i, k) => {
              return (
                <div key={k} className="block">
                  {i.header.display && (
                    <div className="block-header">
                      {ReactHtmlParser(i.header.content)}
                    </div>
                  )}

                  {i.body.display && (
                    <div className="block-body">
                      {i.body.images.map((item, key) => {
                        return (
                          <Img critical={true} key={key}
                            className="image"
                            fluid={item.src.childImageSharp.fluid}
                          ></Img>
                        );
                      })}
                    </div>
                  )}
                  {i.footer.display && (
                    <div className="block-footer">
                      {ReactHtmlParser(i.footer.content)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
    </CustomBlockContainer>
  )
}
