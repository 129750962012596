import React, {useState, useEffect} from 'react'
import styled from "styled-components";
import { scale, rhythm } from "../utils/typography";

const ContainerRow = styled.div`

.title-container {
  @media(min-width: 1024px) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.the-last {
  margin-bottom: 3.5rem;
}

.the-last-top {
  margin-top: 3.5rem;
}
.icon-number {
    font-size: 45px !important;
    color: #91c508;
    font-family: Bebas Neue Bold !important;
    height: 50px;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0;
    border: solid;
    border-radius: 50%;
    padding: 8px 0 0 2px;
    margin-right: 5vw;
  }
  .dv-div-text {
    margin-bottom: 0;
    color: #333;
    font-family: Bebas Neue Bold;
    ${scale(0.25)}
  }
  p {
    color: #555;
  }
  p,
  h2 {
    width: 100%;
  }
  .container {
    padding: 4rem 5vw 4rem;
    text-align: center;

    @media(min-width: 468px) {
      padding-bottom: 5rem;
    }

    @media(min-width: 1024px) {
      padding-bottom: 3rem;
    }

    .img {
      overflow: auto !important;
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 3rem;
      font-weight: bold;
      line-height: 1;

      @media(max-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    p {
      img {
        @media(max-width: 768px) {
          margin-top: -1rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
  
  .item.full {
    img {
      width: 100%;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    &.alt {
      img {
        margin-top: ${rhythm(2)};
        margin-top: ${rhythm(2)};
      }
      i {
        font-size: 70px;
      }
      p {
        width: fit-content;
      }
      .par {
        flex-direction: row;
        i {
          margin-right: 5vw;
        }
        p {
          display: flex;
        }
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column !important;
        img {
          max-width: 450px;
        }
      }
    }
    &.fb {
      flex-flow: wrap;
      flex-direction: row !important;
      justify-content: center;
      .item.np {
        margin: auto;
      }
    }
    .page-without-img {
      margin-bottom: 3rem !important;
    }

    .element-with-img {
      padding-top: 0.5rem !important;
    }

    .item.np {
      
      &:not(:first-child) {
        margin-top: 1rem;
      }
  
      &:not(:last-child) {
        @media(max-width: 1024px) {
          margin-bottom: 2rem;
        }
      }

      &.image {
        p {
          padding: 0 2.5vw !important;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }

      p {
        margin-bottom: 0 !important;
      }
    }
    
    @media screen and (max-width: 768px) {
      flex-direction: column !important;
      
      .item-only-img {
        margin-bottom: 0rem !important;
      }
    }
    .item {
      display: flex;
      width: 100%;
      flex-direction: column;
      h2 {
        margin-bottom: 1rem;
      }
      &.fb-2 {
        flex-basis: 50%;
      }
      &.fb-3 {
        flex-basis: 33.33%;
      }
      &.fb-4 {
        max-width: 350px;
        flex-basis: 25%;
        @media screen and (max-width: 768px) {
          flex-basis: 50%;
        }
        @media screen and (max-width: 580px) {
          flex-basis: 100%;
        }
      }
      &.fb-1 {
        flex-basis: 100%;
      }
      &.center {
        h2,
        p {
          margin-left: auto;
          margin-right: auto;
        }
      }
      @media (max-width: 768px){
        &.center {
          p {
            text-align:left !important;
          }
        }
      }
      &.left {
        p {
          text-align: left !important;
        }
      }
      &.right {
        p,
        h2 {
          text-align: right;
          @media screen and (max-width: 768px) {
            text-align: left !important;
          }
        }
      }
      &:nth-of-type(even) {
        /*padding-left: 2.5vw;*/
      }
      p {
        text-align: center;
      }
      h2 {
        text-align: center;
      }
    }
    .maxwidth-2items {
      @media(min-width: 1024px) {
        max-width: 40% !important;
        flex-basis: 80% !important;
      }
    }
  }
`; 

export const AnnexsRow = (props) => {
    const [rows,setRows] = useState([]);
    const [width, setWidth] = useState(0);

    console.log("annexsRow ",Object.values(props));

    useEffect(() => {
      if(typeof window !== "undefined") {
        setWidth(window.innerWidth);
      }
    },[]);

    useEffect(() => {
      setRows(Object.values(props));
    },[props]);

  return (
    <>
      <ContainerRow>
          <div className="container">
            {rows.length > 0 && rows.map((item,index) => (
                <div key={index}>
                    {item.row.map((row, index2) => {

                      if(!row.thereImageContent) {
                        return (
                          <div className={`row ${rows.length > 1 && (index === (rows.length - 1)) && "the-last-top"}`}key={index2}>
                            <div className="item np left page-without-img">
                            {row.title && (
                                  <>
                                    <div className='title-container'>
                                      <h2>{row.title}</h2>
                                    </div>
                                  </>
                                )}
                                <p>{row.text}</p>
                            </div>
                          </div>
                        );
                      }
                    })}


                    <div className="row fb">

                      {item.row.map((row, index2) => {
    
                        if(row.thereImageContent) {

                          return(
                            <>
                              <div className={`fb-4 item np center element-with-img
                                  ${row.image && !row.title && !row.text && !row.description && "item-only-img"}
                                  ${item.row.length <= 2 && "maxwidth-2items"}`} key={index2}>
                                {row.image && <img src={row.image.publicURL} />}
                                    {row.title && (
                                      <>
                                        <div className='title-container'>
                                          <h2>{row.title}</h2>
                                        </div>
                                      </>
                                    )}
                                    {row.description && (
                                      <div className="description">
                                        {row.description.map((item,index) => (<span key={index}>{item}</span>))}
                                      </div>
                                    )}
                                    {row.text && (
                                        <p>
                                           {row.text}
                                        </p>
                                    )}
                              </div>
                            </>
                          );
                        }
                      })}

                    </div>

                </div>
            ))}
          </div>
      </ContainerRow>
    </>
  )
}
